<div class="deactivate-store">
  <img loading="lazy" class="dukan-created__body__img" src="{{ assetsPath + 'deactivated.svg' }}" />
  <section class="deactivate-store-text">
    <p class="deactivate-store-text--title heading2--bold">
      {{ 'STORES.DUKAN.DUKAN_DEACTIVATED.TITLE' | translate }}
    </p>
    <p class="deactivate-store-text--body body2--regular">
      {{ 'STORES.DUKAN.DUKAN_DEACTIVATED.BODY' | translate }}
    </p>
  </section>
  <button class="deactivate-store-close body2--medium" (click)="onCloseClicked()">
    {{ 'STORES.DUKAN.DUKAN_DEACTIVATED.CONFIRM' | translate }}
  </button>
</div>
