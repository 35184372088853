import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { CurrencyShortNamePipe } from 'src/app/presentation/shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from 'src/app/presentation/shared/pipes/currency-translate.pipe';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
// eslint-disable-next-line import/extensions, import/no-extraneous-dependencies
import 'quill-emoji/dist/quill-emoji.js';
import { Subscription } from 'rxjs';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';

@Component({
  selector: 'app-pricing-options-card',
  standalone: true,
  imports: [
    TranslateModule,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
    NgIf,
    QuillModule,
    MatSlideToggleModule,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './pricing-options-card.component.html',
  styleUrls: ['./pricing-options-card.component.scss'],
})
export class PricingOptionsCardComponent implements OnInit {
  @Input() pricingOptionsFormGroup: any;

  @Input() priceCardIndex: number;

  @Input() isEditModeEnabled: boolean;

  @Input() shouldDisableDeleteButton: boolean;

  @Output() deletedClicked: EventEmitter<void> = new EventEmitter();

  @Output() toggleClicked: EventEmitter<void> = new EventEmitter();

  public currency: string;

  public isLTR = false;

  public descriptionTextLength: number;

  public nameTextLength: number;

  public maxDescriptionLength = 140;

  public maxNameLength = 20;

  public isNameInvalid = false;

  public isDescriptionInvalid = false;

  public initialData: any;

  public quillNameConfiguration = {
    toolbar: [['emoji']],
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': true,
  };

  public quillDescriptionConfiguration = {
    toolbar: [['strike']],
  };

  private _languageChangeSubscription: Subscription;

  private _multiTenancyService = inject(MultitenancyService);

  private _siteTranslateService = inject(SiteTranslateService);

  private _translateService = inject(TranslateService);

  ngOnInit(): void {
    this.isLTR = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isLTR = event.lang === ENGLISH_LANGUAGE;
      },
    });
    this.currency = this._multiTenancyService.getCurrentCountry().currency.arabicName;
    const removedDescriptionTags = this.removeTextTags(
      this.pricingOptionsFormGroup.value.description,
    );
    const removedNameTags = this.removeTextTags(this.pricingOptionsFormGroup.value.name);
    this.descriptionTextLength = removedDescriptionTags?.length || 0;
    this.nameTextLength = removedNameTags?.length;
    this.initialData = this.pricingOptionsFormGroup.value;
  }

  removeTextTags(text: string): string {
    return text?.replace(/<[^>]*>/g, '');
  }

  onEditClicked(): void {
    this.pricingOptionsFormGroup.get('isEditMode').setValue(true);
  }

  onSaveClicked(): void {
    this.initialData = this.pricingOptionsFormGroup.value;
    this.pricingOptionsFormGroup.get('isEditMode').setValue(false);
  }

  onCancelClicked(): void {
    this.pricingOptionsFormGroup.patchValue(this.initialData);
    this.pricingOptionsFormGroup.get('isEditMode').setValue(false);
  }

  onDeleteClicked(): void {
    this.deletedClicked.emit();
  }

  onToggleClicked(): void {
    this.toggleClicked.emit();
  }

  onNameChanged(input: any): void {
    const modifiedText = this.removeTextTags(input.html);
    if (modifiedText) {
      this.nameTextLength = modifiedText?.length;
      this.isNameInvalid = modifiedText?.length > this.maxNameLength;
    } else {
      this.nameTextLength = 0;
      this.isNameInvalid = true;
    }
  }

  onDescriptionChanged(input: any): void {
    const modifiedText = this.removeTextTags(input.html);
    if (modifiedText) {
      this.descriptionTextLength = modifiedText?.length;
      this.isDescriptionInvalid = modifiedText?.length > this.maxDescriptionLength;
    } else {
      this.descriptionTextLength = 0;
    }
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
