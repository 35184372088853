import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { DukanProductModel } from 'src/app/core/domain/dukan-shop/dukan-product.model';

@Component({
  selector: 'app-toggle-product-added',
  templateUrl: './toggle-product-added.component.html',
  styleUrls: ['./toggle-product-added.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MatSlideToggleModule, TranslateModule],
})
export class ToggleProductAddedComponent {
  @Input() product: DukanProductModel;

  setSliderValue(event: any): void {
    if (event.checked) {
      this.product.status = 'active';
    } else {
      this.product.status = 'draft';
    }
  }
}
