<div class="add-product">
  <section class="add-product__header">
    <p class="body2--bold primary-dark-color">
      {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.ADD_PRODUCT' | translate }}
    </p>
  </section>
  <form [formGroup]="addProductForm">
    <section class="add-product__field">
      <p class="body2--regular content-main-color">
        {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.PRODUCT_SKU' | translate }}
      </p>
      <input
        class="add-product__field__input caption1--medium content-main-color"
        type="text"
        placeholder="Product SKU"
        formControlName="sku"
      />
      <button
        class="add-product__field__button body1--medium"
        type="button"
        [disabled]="addProductForm.invalid || isLoading"
        (click)="onPushProduct()"
      >
        {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.ADD' | translate }}
      </button>
    </section>
  </form>
</div>
<div class="edit-dukan">
  <section class="edit-dukan--products">
    <section class="edit-dukan--products--header">
      <div class="edit-dukan--products--header--text">
        <p class="edit-dukan--products--header--text--title body2--bold">
          {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.CATALOG_PRODUCTS' | translate }}
          <span class="body2--bold primary-dark-color">
            ({{ addedProductsCount }}
            {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.PRODUCT' | translate }})</span
          >
        </p>
        <p class="edit-dukan--products--header--text--description caption1--regular">
          {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.CATALOG_PRODUCTS_NOTE' | translate }}
        </p>
      </div>
    </section>
    <loader size="md" [loading]="isLoading" class="loader"></loader>
    <section class="edit-dukan--products--list" *ngIf="!isLoading">
      <div *ngFor="let product of dukanProducts">
        <app-dukan-product-card
          [product]="product"
          [dukanUrl]="dukanUrl"
          (deleteButtonClicked)="onDeleteProduct($event)"
        ></app-dukan-product-card>
      </div>
      <p
        *ngIf="dukanProducts?.length === 0"
        class="edit-dukan--products--empty-products body2--medium"
      >
        {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.ADD_PRODUCTS' | translate }}
      </p>
    </section>
  </section>
</div>
