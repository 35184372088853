<div class="integration" *ngFor="let integration of integrationList">
  <div class="integration__header">
    <img [src]="integration.icon" [alt]="integration.alt" />
    <p class="primary-dark-color body1--bold">{{ integration.title }}</p>
  </div>
  <div class="integration__body">
    <p class="content-main-color body2--regular">{{ integration.name }}</p>
    <form [formGroup]="integrationForm">
      <div class="integration__body__upper">
        <input
          class="integration__body__input caption1--medium content-main-color"
          type="text"
          [formControlName]="integration.controlName"
          [placeholder]="integration.placeholder"
        />
        <button
          [disabled]="!integrationForm.get(integration.controlName)?.value"
          class="integration__body__add-btn body1--medium"
          type="button"
          (click)="addInput(integration.controlName, integration.type)"
        >
          {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.ADD' | translate }}
        </button>
      </div>
      <div class="integration__body__lower" *ngIf="integration.integrationData?.length > 0">
        <p class="content-medium-color body2--regular">
          {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.ADDED_IDS' | translate }}
          {{ integration.title }}
        </p>
        <div
          class="integration__body__added"
          *ngFor="let id of integration.integrationData; let i = index"
        >
          <p class="body1--medium content-main-color">{{ id }}</p>
          <button
            class="integration__body__added__delete-btn"
            (click)="removeInput(i, integration.type)"
          >
            <img src="{{ assetsPath + 'delete.svg' }}" alt="delete-icon" />
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
