import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { DukanProductModel } from 'src/app/core/domain/dukan-shop/dukan-product.model';
import { DeleteDukanProductUseCase } from 'src/app/core/usecases/dukan-shop/delete-dukan-product.usecase';
import { GetDukanProductsUseCase } from 'src/app/core/usecases/dukan-shop/get-dukan-products.usecase';
import { PushDukanProductUseCase } from 'src/app/core/usecases/dukan-shop/push-dukan-product.usecase';
import { SetDukanProductsUseCase } from 'src/app/core/usecases/dukan-shop/set-dukan-products.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { LoaderComponent } from 'src/app/presentation/shared/components/loader/loader.component';
import { SideDialogComponent } from '../../../shared/components/side-dialog/side-dialog.component';
import { DukanContactInfoCardComponent } from './dukan-contact-info-card/dukan-contact-info-card.component';
import { DukanProductCardComponent } from './dukan-product-card/dukan-product-card.component';

@Component({
  selector: 'app-edit-your-dukan',
  templateUrl: './edit-your-dukan.component.html',
  styleUrls: ['./edit-your-dukan.component.scss'],
  standalone: true,
  imports: [
    SideDialogComponent,
    NgFor,
    NgIf,
    NgClass,
    DukanProductCardComponent,
    DukanContactInfoCardComponent,
    TranslateModule,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
  ],
})
export class EditYourDukanComponent implements OnInit {
  public dukanProducts: DukanProductModel[];

  @Input() dukanUrl: string;

  public addedProductsCount: number;

  public addProductForm: FormGroup;

  public isLoading = false;

  public dukanProductAlreadyExists = 'product_already_exists';

  constructor(
    private _getDukanProductsUseCase: GetDukanProductsUseCase,
    private _setDukanProductsUseCase: SetDukanProductsUseCase,
    private _toast: ToastrService,
    private _translateService: TranslateService,
    private _pushDukanProductUseCase: PushDukanProductUseCase,
    private _deleteDukanProductUseCase: DeleteDukanProductUseCase,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {}

  ngOnInit(): void {
    this.getProducts();
    this.addProductForm = new FormGroup({
      sku: new FormControl('', [Validators.required]),
    });
  }

  getProducts(): void {
    this.isLoading = true;
    this._getDukanProductsUseCase
      .execute()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.dukanProducts = res;
          this.addedProductsCount = this.dukanProducts.filter(
            (product) => product.status === 'active',
          ).length;
        },
      });
  }

  onSetProducts(): void {
    const products = this.dukanProducts.map(({ sku, status }) => ({ sku, status }));
    this._setDukanProductsUseCase.execute(products).subscribe({
      next: () => {
        this._toast.success(
          this._translateService.instant('STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.SUCCESS'),
        );
        this.getProducts();
      },
      error: () => {
        this._toast.error(this._translateService.instant('STORES.DUKAN.ERRORS.UPDATED'));
      },
    });
  }

  onPushProduct(): void {
    this.isLoading = true;
    const productSku = this.addProductForm.get('sku')!.value;
    this._pushDukanProductUseCase
      .execute(productSku)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.getProducts();
          this._toast.success(
            this._translateService.instant('STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.SUCCESS'),
          );
          this.addProductForm.get('sku')!.reset();
        },
        error: (err) => {
          if (err.error.error === this.dukanProductAlreadyExists) {
            this._toast.error(this._translateService.instant('STORES.DUKAN.ERRORS.PRODUCT_EXISTS'));
          } else {
            this._toast.error(this._translateService.instant('STORES.DUKAN.ERRORS.UPDATED'));
          }
        },
      });
  }

  onDeleteProduct(sku: string): void {
    this._deleteDukanProductUseCase.execute(sku).subscribe({
      next: () => {
        this._toast.success(
          this._translateService.instant('STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.DELETED'),
        );
        this.getProducts();
      },
      error: () => {
        this._toast.error(this._translateService.instant('STORES.DUKAN.ERRORS.UPDATED'));
      },
    });
  }
}
