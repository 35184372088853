import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { YOUCAN_PROVIDER_NAME, ZID_PROVIDER_NAME } from 'src/app/presentation/shared/constants';

@Component({
  selector: 'app-store-linking-error',
  templateUrl: './store-linking-error.component.html',
  styleUrls: ['./store-linking-error.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgIf],
})
export class StoreLinkingErrorComponent {
  public assetsURL = 'assets/img/';

  public zidProvider: string = ZID_PROVIDER_NAME;

  public youcanProvider: string = YOUCAN_PROVIDER_NAME;

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {}

  closeDialog(): void {
    this._router.navigate([this._appURLs.STORES_URL]);
    this._dialog.closeAll();
  }
}
