<div class="linking-failed">
  <img src="{{ assetsURL + 'store-error.svg' }}" />
  <p class="heading2--bold content-main-color">{{ 'STORES.ADDED_FAILED' | translate }}</p>
  <p *ngIf="data.provider === zidProvider" class="caption1--medium content-medium-color">
    {{ 'STORES.ZID_ADDED_FAILED_MESSAGE' | translate }}
  </p>
  <p *ngIf="data.provider === youcanProvider" class="caption1--medium content-medium-color">
    {{ 'STORES.YOUCAN_ADDED_FAILED_MESSAGE' | translate }}
  </p>
  <button (click)="closeDialog()" class="linking-failed__button body2--medium">
    {{ 'STORES.OKAY' | translate }}
  </button>
</div>
