import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-unlinked-successfully-pop-up',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './unlinked-successfully-pop-up.component.html',
  styleUrls: ['./unlinked-successfully-pop-up.component.scss'],
})
export class UnlinkedSuccessfullyPopUpComponent {
  public assetsURL = 'assets/img/';

  constructor(private _dialogRef: MatDialogRef<UnlinkedSuccessfullyPopUpComponent>) {}

  closeDialog() {
    this._dialogRef.close();
  }
}
