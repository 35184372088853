<div class="linked-successfully">
  <img src="{{ assetsURL + 'success-store.svg' }}" />
  <p class="heading2--bold content-main-color">{{ 'STORES.CONNECTED_SUCCESSFULLY' | translate }}</p>
  <div class="linked-successfully__header">
    <p class="caption1--medium content-medium-color">
      {{ 'STORES.EXPECTED_QUESTION' | translate }}
    </p>
    <div class="linked-successfully__description">
      <img src="{{ assetsURL + 'store-icon.svg' }}" />
      <p class="body2--bold content-main-color">{{ 'STORES.EXPECTED_ANSWER_1' | translate }}</p>
    </div>
    <div class="linked-successfully__description">
      <img src="{{ assetsURL + 'box.svg' }}" />
      <p class="body2--bold content-main-color">{{ 'STORES.EXPECTED_ANSWER_2' | translate }}</p>
    </div>
    <div class="linked-successfully__description">
      <img src="{{ assetsURL + 'big-car.svg' }}" />
      <p class="body2--bold content-main-color">{{ 'STORES.EXPECTED_ANSWER_3' | translate }}</p>
    </div>
    <div class="linked-successfully__description">
      <img src="{{ assetsURL + 'update.svg' }}" />
      <p class="body2--bold content-main-color">{{ 'STORES.EXPECTED_ANSWER_4' | translate }}</p>
    </div>
  </div>
  <button (click)="closeDialog()" class="linked-successfully__button body2--medium">
    {{ 'STORES.OKAY' | translate }}
  </button>
</div>
