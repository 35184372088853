<div class="dukan-created">
  <img
    loading="lazy"
    class="dukan-created__close"
    (click)="closeDialog()"
    src="{{ assetsPath + 'close' }}.svg"
  />
  <div class="dukan-created__body">
    <img loading="lazy" class="dukan-created__body__img" src="{{ assetsPath + 'checkmark' }}.svg" />
    <div *ngIf="data.reactivated; then thenBlock; else elseBlock"></div>
    <ng-template #thenBlock
      ><p class="heading2--bold content-main-color">
        {{ 'STORES.DUKAN.REACTIVATED' | translate }}
      </p></ng-template
    >
    <ng-template #elseBlock>
      <p class="heading2--bold content-main-color">
        {{ 'STORES.DUKAN.CREATED.READY' | translate }}
      </p>
    </ng-template>
    <p class="bold2--regular content-medium-color">
      {{ 'STORES.DUKAN.CREATED.SHARE' | translate }}
    </p>
    <div class="dukan-created__body__cta">
      <p class="dukan-created__body__cta__slug body2--regular content-main-color">{{ data.url }}</p>
      <button (click)="copyDukanSlug()" class="dukan-created__body__cta__btn body2--medium">
        {{ 'STORES.DUKAN.CREATED.COPY' | translate }}
      </button>
    </div>
  </div>
</div>
