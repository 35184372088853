<loader size="md" *ngIf="isLoading" [loading]="isLoading" class="loader"></loader>
<div class="edit-product-page" *ngIf="!isLoading">
  <form [formGroup]="editProductFormGroup">
    <div class="edit-product">
      <section class="edit-product__header">
        <p class="body2--bold primary-dark-color">
          {{ 'STORES.DUKAN.EDIT_PRODUCT.NAME' | translate }}
        </p>
      </section>
      <section class="edit-product__field">
        <p
          class="body2--regular content-main-color"
          [ngClass]="{
            'error-color': editProductFormGroup.get('name')!.invalid
          }"
        >
          {{ 'STORES.DUKAN.EDIT_PRODUCT.NAME' | translate }} <span class="error-color">*</span>
        </p>
        <input
          class="edit-product__field__input caption1--medium content-main-color"
          type="text"
          formControlName="name"
        />
      </section>
    </div>
    <div class="edit-product">
      <section class="edit-product__header">
        <p class="body2--bold primary-dark-color">
          {{ 'STORES.DUKAN.EDIT_PRODUCT.DESCRIPTION' | translate }}
        </p>
      </section>
      <section class="edit-product__field">
        <p
          class="body2--regular content-main-color"
          [ngClass]="{
            'error-color': editProductFormGroup.get('detailedDescription')!.invalid
          }"
        >
          {{ 'STORES.DUKAN.EDIT_PRODUCT.DETAILED_DESCRIPTION' | translate }}
          <span class="error-color">*</span>
        </p>
        <textarea
          *ngIf="!isTextEditorEnabled"
          class="edit-product__field__input big-input caption1--medium content-main-color"
          type="text"
          formControlName="detailedDescription"
          (keyup)="onProductSpecificationsChange()"
        ></textarea>
        <quill-editor
          *ngIf="isTextEditorEnabled"
          class="caption1--medium notranslate"
          formControlName="detailedDescription"
          [styles]="{ 'min-height': '140px', direction: isEnglishLanguage ? 'ltr' : 'rtl' }"
          [modules]="quillConfiguration"
          (onEditorCreated)="onEditorCreated($event)"
          [placeholder]="''"
        >
        </quill-editor>
      </section>
    </div>
    <div class="edit-product">
      <section class="edit-product__header">
        <p class="body2--bold primary-dark-color">
          {{ 'STORES.DUKAN.EDIT_PRODUCT.PRICE' | translate }}
        </p>
      </section>
      <div class="edit-product__inputs-container">
        <div class="edit-product__inputs-container__options">
          <div class="edit-product__inputs-container__options__header">
            <p class="caption1--bold content-main-color">
              {{ 'STORES.DUKAN.EDIT_PRODUCT.DO' | translate }}
              <span class="caption1--bold primary-dark-color">{{
                'STORES.DUKAN.EDIT_PRODUCT.FREE_SHIPPING' | translate
              }}</span
              >{{ 'STORES.DUKAN.EDIT_PRODUCT.CONFIRM' | translate }}
            </p>
          </div>
          <app-pricing-options-card
            *ngFor="let option of pricingOptions.controls; let i = index"
            [pricingOptionsFormGroup]="option"
            [priceCardIndex]="i"
            [isEditModeEnabled]="isEditModeEnabled"
            [shouldDisableDeleteButton]="shouldDisableDeleteButton"
            (deletedClicked)="deletePricingOption(i)"
            (toggleClicked)="toggleSelection(i)"
          ></app-pricing-options-card>
          <button
            (click)="addPricingOption()"
            [disabled]="isEditModeEnabled"
            class="edit-product__inputs-container__options__button body2--bold"
          >
            <img src="{{ assetsPath + 'plus.svg' }}" alt="add-option-icon" />
            {{ 'STORES.DUKAN.EDIT_PRODUCT.ADD_NEW_OPTION' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="edit-product">
      <section class="edit-product__header">
        <p class="body2--bold primary-dark-color">
          {{ 'STORES.DUKAN.EDIT_PRODUCT.MEDIA' | translate }}
        </p>
      </section>
      <section class="edit-product__field">
        <p class="body2--regular content-main-color">
          {{ 'STORES.DUKAN.EDIT_PRODUCT.MINIMUM_SIZE' | translate }} {{ mediaSize }}
        </p>
        <div class="edit-product__uploader">
          <div class="img-preview" *ngFor="let item of mediaGallery; let i = index">
            <img *ngIf="!item.isVideo" [src]="item.url" class="preview" alt="product-img" />
            <div *ngIf="item.isVideo" class="video-thumbnail-container">
              <video
                [src]="item.url"
                class="preview"
                controls="true"
                name="Dukan product video"
                type="video/mp4"
                preload="metadata"
              ></video>
            </div>
            <button class="remove-media-btn" (click)="deleteMedia(item.url, i)">
              <img src="{{ assetsPath + 'remove.svg' }}" alt="delete-icon" />
            </button>
          </div>
          <loader
            size="md"
            *ngIf="mediaIsUploading"
            [loading]="mediaIsUploading"
            class="loader"
          ></loader>
          <label class="upload-btn" *ngIf="!mediaIsUploading">
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .mp4, video/*"
              (change)="uploadFiles($event)"
              hidden
            />
            <img class="upload-btn__image" src="{{ assetsPath + 'add.svg' }}" alt="upload-icon" />
            <p class="caption1--medium content-medium-color">
              {{ 'STORES.DUKAN.EDIT_PRODUCT.ADD_MORE' | translate }}
            </p>
          </label>
        </div>
      </section>
    </div>
  </form>
</div>
