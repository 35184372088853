<div class="dukan-page">
  <a class="dukan-page__back" [routerLink]="[appURLs.STORES_URL]">
    <i
      class="icon dukan-page__back__img"
      [ngClass]="isEnglishLanguage ? 'icon-dukan-icons-back-en' : 'icon-dukan-icons-back'"
    ></i>
    <p class="caption2--regular content-medium-color">
      {{ 'STORES.DUKAN.BACK' | translate }}
    </p>
  </a>
  <div class="dukan-page__controller">
    <p class="heading2--bold content-main-color">
      {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.EDIT_STORE' | translate }}
    </p>
    <p class="caption1--bold content-main-color">
      {{ 'STORES.DUKAN.DUKAN_STORE' | translate }}
      <span class="body2--bold content-main-color">”{{ dukanShopName }}“</span>
    </p>
    <mat-tab-group
      animationDuration="0ms"
      class="tab-group"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab label="products">
        <ng-template mat-tab-label>
          <i class="icon icon-product"></i>
          <div class="caption1--medium">{{ 'STORES.DUKAN.PRODUCTS' | translate }}</div>
        </ng-template>
        <div class="tab-content">
          <app-edit-your-dukan
            *ngIf="editDukanPageSelected"
            #dukanProducts
            [dukanUrl]="dukanUrl"
          ></app-edit-your-dukan>
          <app-edit-dukan-product
            *ngIf="!editDukanPageSelected"
            #dukanProductDetails
          ></app-edit-dukan-product>
        </div>
      </mat-tab>
      <mat-tab label="settings">
        <ng-template mat-tab-label>
          <i class="icon icon-setting"></i>
          <div class="caption1--medium">{{ 'STORES.DUKAN.DUKAN_SETTINGS' | translate }}</div>
        </ng-template>
        <div class="tab-content">
          <app-dukan-contact-info-card #dukanSettings></app-dukan-contact-info-card>
        </div>
      </mat-tab>
      <mat-tab label="integrations">
        <ng-template mat-tab-label>
          <i class="icon icon-puzzles"></i>
          <div class="caption1--medium">{{ 'STORES.DUKAN.INTEGRATIONS' | translate }}</div>
        </ng-template>
        <div class="tab-content">
          <app-dukan-integrations
            *ngIf="integrationIds"
            #dukanIntegrations
            [integrationIds]="integrationIds"
          ></app-dukan-integrations>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="dukan-page__CTA">
      <a class="dukan-page__back body2--bold" [routerLink]="[appURLs.STORES_URL]">
        {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.CANCEL' | translate }}
      </a>
      <button
        class="dukan-page__CTA__apply body2--bold"
        (click)="onSaveChanges()"
        [disabled]="dukanProductDetails?.editProductFormGroup.invalid"
      >
        {{ 'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.APPLY' | translate }}
      </button>
    </div>
  </div>
</div>
