<form [formGroup]="infoForm">
  <div class="info-card">
    <section class="info-card--header">
      <p class="info-card--header--text body1--bold">
        {{ 'STORES.DUKAN.DUKAN_INFO_CARD.TITLE' | translate }}
      </p>
    </section>
    <div class="info-card--form-group">
      <input
        class="form-control body2--medium info-card--form-group--email"
        type="text"
        placeholder="{{ 'STORES.DUKAN.DUKAN_INFO_CARD.EMAIL' | translate }}"
        formControlName="email"
      />
      <div *ngIf="enterNumberClicked || user.phoneNumber; then thenBlock; else elseBlock"></div>
      <ng-template #thenBlock>
        <input
          class="form-control body2--medium info-card--form-group--email"
          type="number"
          placeholder="{{ 'STORES.DUKAN.DUKAN_INFO_CARD.NUMBER' | translate }}"
          formControlName="phoneNumber"
        />
      </ng-template>
      <ng-template #elseBlock
        ><button class="info-card--form-group--number" (click)="addNumberClicked()">
          + {{ 'STORES.DUKAN.DUKAN_INFO_CARD.NUMBER' | translate }}
        </button></ng-template
      >
    </div>
  </div>
  <div class="info-card">
    <section class="info-card--header">
      <p class="info-card--header--text body1--bold">
        {{ 'STORES.DUKAN.CHECKOUT_FORM' | translate }}
      </p>
    </section>
    <div class="checkout-form">
      <mat-radio-group formControlName="checkoutType" class="button-container">
        <mat-radio-button [value]="true">
          <div class="button-container__content">
            <img src="{{ assetsPath + 'express-checkout.svg' }}" alt="express-checkout" />
            <p class="content-main-color body2--bold">
              {{ 'STORES.DUKAN.CHECKOUT_FORM_EXPRESS' | translate }}
            </p>
            <p class="content-medium-color caption1--medium">
              {{ 'STORES.DUKAN.CHECKOUT_FORM_EXPRESS_INFO' | translate }}
            </p>
          </div>
        </mat-radio-button>
        <mat-radio-button [value]="false">
          <div class="button-container__content">
            <img src="{{ assetsPath + 'default-checkout.svg' }}" alt="default-checkout" />
            <p class="content-main-color body2--bold">
              {{ 'STORES.DUKAN.CHECKOUT_FORM_DEFAULT' | translate }}
            </p>
            <p class="content-medium-color caption1--medium">
              {{ 'STORES.DUKAN.CHECKOUT_FORM_DEFAULT_INFO' | translate }}
            </p>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</form>
<div class="deactivate">
  <p class="body2--medium">{{ 'STORES.DUKAN.DEACTIVATE_DUKAN.DEACTIVATE_NOW' | translate }}</p>
  <button class="deactivate__btn caption1--medium" (click)="onDeactivateClicked()">
    {{ 'STORES.DUKAN.DEACTIVATE' | translate }}
  </button>
</div>
