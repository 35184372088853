import { NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DukanProductModel } from 'src/app/core/domain/dukan-shop/dukan-product.model';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { OnHoverTooltipComponent } from 'src/app/presentation/shared/components/on-hover-tooltip/on-hover-tooltip.component';
import { ProductAttributesComponent } from 'src/app/presentation/shared/components/product-attributes/product-attributes.component';
import { CurrencyShortNamePipe } from 'src/app/presentation/shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from 'src/app/presentation/shared/pipes/currency-translate.pipe';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { HoverStyleDirective } from '../../../../shared/directives/hover-style.directive';
import { ToggleProductAddedComponent } from './toggle-product-added/toggle-product-added.component';

@Component({
  selector: 'app-dukan-product-card',
  templateUrl: './dukan-product-card.component.html',
  styleUrls: ['./dukan-product-card.component.scss'],
  standalone: true,
  imports: [
    HoverStyleDirective,
    ToggleProductAddedComponent,
    TranslateModule,
    ProductAttributesComponent,
    NgIf,
    RouterLink,
    OnHoverTooltipComponent,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
  ],
})
export class DukanProductCardComponent implements OnInit {
  @Input() product: DukanProductModel;

  @Input() dukanUrl: string;

  public sanitizedProductUrl: SafeUrl;

  public productSku: string;

  public currency: string;

  private _sanitizer = inject(DomSanitizer);

  public appURLs = inject(appUrlsConstantsInjectionToken);

  private _multiTenancyService = inject(MultitenancyService);

  @Output() deleteButtonClicked = new EventEmitter<string>();

  ngOnInit(): void {
    this.sanitizedProductUrl = this._sanitizer.bypassSecurityTrustUrl(
      `${this.dukanUrl}/products/${this.product.sku}`,
    );
    this.productSku = this.product.sku;
    this.currency = this._multiTenancyService.getCurrentCountry().currency.arabicName;
  }

  onDeleteProductClicked(): void {
    this.deleteButtonClicked.emit(this.productSku);
  }
}
