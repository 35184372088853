<div class="unlink-store">
  <p class="unlink-store__header heading2--bold content-main-color">
    {{ 'STORES.UNLINK_STORE.HEADER' | translate }}
  </p>
  <p class="caption1--medium content-main-color start">
    {{ 'STORES.UNLINK_STORE.TO_EXPECT' | translate }}
  </p>
  <p class="body2--medium content-medium-color start">
    {{ 'STORES.UNLINK_STORE.EXPECT_1' | translate }}
  </p>
  <p class="body2--medium content-medium-color start">
    {{ 'STORES.UNLINK_STORE.EXPECT_2' | translate }}
  </p>
  <p class="body2--medium content-medium-color start">
    {{ 'STORES.UNLINK_STORE.EXPECT_3' | translate }}
  </p>
  <form [formGroup]="unlinkForm" (ngSubmit)="onSubmit()" class="unlink-store">
    <mat-checkbox formControlName="acceptanceCheck" class="start">
      <p class="caption1--medium content-main-color">
        {{ 'STORES.UNLINK_STORE.AGREE' | translate }}
      </p>
    </mat-checkbox>
    <button
      class="unlink-store__button body2--medium"
      [disabled]="unlinkForm.invalid"
      type="submit"
    >
      {{ 'STORES.UNLINK_STORE.SUBMIT' | translate }}
    </button>
  </form>
</div>
