import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { toastError } from '@presentation/shared/toast';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GenerateAPIKeyUseCase } from 'src/app/core/usecases/merchant-store/generate-api-key-usecase';
import { GetAPIKeysUseCase } from 'src/app/core/usecases/merchant-store/get-api-keys-usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { DateWrapperUtility } from 'src/app/presentation/shared/utilities/date-wrapper.utility';
import { APIKeysIntegrationSideEffect } from './api-keys-integration.sideEffects';
import { APIKeysIntegrationViewEvents } from './api-keys-integration.viewEvents';
import { APIKeysIntegrationViewState } from './api-keys-integration.viewState';

let apiKeyInfo: {
  apiKey: string;
  expiresAt: string;
} | null = null;

@Injectable()
export class APIKeysIntegrationPresenter extends BasePresenter<
  APIKeysIntegrationViewState,
  APIKeysIntegrationViewEvents,
  APIKeysIntegrationSideEffect
> {
  private _getAPIKeysUseCase = inject(GetAPIKeysUseCase);

  private _generateAPIKeyUseCase = inject(GenerateAPIKeyUseCase);

  private _siteTranslateService = inject(SiteTranslateService);

  private _translateService = inject(TranslateService);

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  protected defaultViewState(): APIKeysIntegrationViewState {
    return {
      isLoading: !!apiKeyInfo,
      apiKey: apiKeyInfo?.apiKey || '',
      expiresAt: apiKeyInfo?.expiresAt || '',
    };
  }

  calculateCurrentFormattedDate(date: string): string {
    const selectedLanguage = this._siteTranslateService.getCurrentLanguage();
    const { year, month, day } = DateWrapperUtility.getFormattedDateParts(
      date,
      { day: 'D', month: 'MMM', year: 'YYYY' },
      selectedLanguage,
    );
    return `${day} ${month} ${year}`;
  }

  fetchingData(): void {
    if (apiKeyInfo) {
      return;
    }

    const currentDate = new Date();
    const expiryDate = new Date(currentDate).setFullYear(currentDate.getFullYear() + 1);
    const formattedExpiryDate = new Date(expiryDate).toISOString().slice(0, 10);
    this._getAPIKeysUseCase.execute().subscribe({
      next: (data) => {
        if (data.length === 0 || !data[0].isActive || data[0].isExpired) {
          this._generateAPIKeyUseCase.execute(formattedExpiryDate).subscribe({
            next: (res) => {
              this.updateViewState({
                isLoading: false,
                apiKey: res.apiKey,
                expiresAt: this.calculateCurrentFormattedDate(res.expiresAt),
              });

              apiKeyInfo = {
                apiKey: res.apiKey,
                expiresAt: res.expiresAt,
              };
            },
            error: () => {
              toastError(this._translateService.instant('ERRORS.SUPPORT_TEAM'));
              this.updateViewState({
                ...this.viewState,
                isLoading: false,
              });
            },
          });
        } else {
          this.updateViewState({
            isLoading: false,
            apiKey: data[0].apiKey,
            expiresAt: this.calculateCurrentFormattedDate(data[0].expiresAt),
          });
        }
      },
      error: () => {
        toastError(this._translateService.instant('ERRORS.SUPPORT_TEAM'));
        this.updateViewState({
          ...this.viewState,
          isLoading: false,
        });
      },
    });
  }

  protected onViewEvent(event: APIKeysIntegrationViewEvents): void {
    switch (event.type) {
      case 'Init': {
        if (!apiKeyInfo) {
          this.updateViewState({
            ...this.viewState,
            isLoading: true,
          });
        }
        this.fetchingData();
        break;
      }
      case 'ClickCopyKey': {
        this._logMixpanelEventUseCase.execute({ eventName: 'stores_copy_api_key_clicked' });
        this.emitSideEffect({
          type: 'CopyKey',
        });
        break;
      }
    }
  }
}
