<div class="setup-dukan">
  <img
    loading="lazy"
    class="setup-dukan__close-btn"
    (click)="closeDialog()"
    src="{{ assetsPath + 'close' }}.svg"
  />
  <form [formGroup]="createDukanForm" (ngSubmit)="onSubmit()">
    <div class="setup-dukan__name">
      <p class="heading2--bold content-main-color">
        {{ 'STORES.DUKAN.SETUP.HEADER' | translate }}
      </p>
      <p class="body2--regular content-medium-color">
        {{ 'STORES.DUKAN.SETUP.DESCRIPTION' | translate }}
      </p>
      <p class="body2--regular content-main-color">
        {{ 'STORES.DUKAN.SETUP.INPUT' | translate }}
        {{ 'STORES.DUKAN.SETUP.ENGLISH_ONLY' | translate }}
      </p>
      <input
        class="setup-dukan__name__input"
        formControlName="dukanName"
        placeholder="Example"
        [(ngModel)]="storeName"
        (input)="onInput($event)"
      />
      <bdi class="body2--regular content-light-color">
        {{ storeName || 'example' | slugify }}{{ 'STORES.DUKAN.SETUP.EXAMPLE' | translate }}
      </bdi>
    </div>
    <div class="setup-dukan__shipping">
      <p class="body2--medium content-main-color">
        {{ 'STORES.DUKAN.SETUP.SHIPPING_QUESTION' | translate }}
      </p>
      <p class="caption1--regular content-medium-color">
        {{ 'STORES.DUKAN.SETUP.SHIPPING_ANSWER' | translate }}
      </p>
      <p class="caption1--regular content-medium-color">
        <span class="caption1--bold content-medium-color">{{
          'STORES.DUKAN.SETUP.SHIPPING_EXAMPLE_1' | translate
        }}</span>
        {{ 'STORES.DUKAN.SETUP.SHIPPING_EXAMPLE_2' | translate }}
      </p>
      <mat-checkbox
        formControlName="shippingFeesCheck"
        class="setup-dukan__shipping__checkbox caption1--bold content-main-color"
        >{{ 'STORES.DUKAN.SETUP.SHIPPING_CHECKBOX' | translate }}</mat-checkbox
      >
    </div>
    <div class="setup-dukan__create">
      <button class="setup-dukan__create__btn" [disabled]="createDukanForm.invalid" type="submit">
        {{ 'STORES.DUKAN.SETUP.CREATE_BUTTON' | translate }}
      </button>
    </div>
  </form>
</div>
