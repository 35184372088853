import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { ToastrService } from 'ngx-toastr';
// eslint-disable-next-line import/no-extraneous-dependencies
import Quill from 'quill';
import { finalize, Subscription } from 'rxjs';
import { UpdateDukanProductModel } from 'src/app/core/domain/dukan-shop/dukan-product.model';
import { AddDukanProductMediaUseCase } from 'src/app/core/usecases/dukan-shop/add-dukan-product-media.usecase';
import { DeleteDukanProductMediaUseCase } from 'src/app/core/usecases/dukan-shop/delete-dukan-product-media.usecase';
import { GetDukanProductDetailsUseCase } from 'src/app/core/usecases/dukan-shop/get-dukan-product-details.usecase';
import { UpdateDukanProductDetailsUseCase } from 'src/app/core/usecases/dukan-shop/update-dukan-product-details.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { LoaderComponent } from 'src/app/presentation/shared/components/loader/loader.component';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { CurrencyShortNamePipe } from 'src/app/presentation/shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from 'src/app/presentation/shared/pipes/currency-translate.pipe';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { isVideoFromUrl } from 'src/app/presentation/shared/utilities/is-video.utility';
// eslint-disable-next-line import/extensions, import/no-extraneous-dependencies
import 'quill-emoji/dist/quill-emoji.js';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { IS_RICH_TEXT_EDITOR_ENABLED } from 'src/app/presentation/shared/constants/feature-flags';
import { PricingOptionsCardComponent } from './pricing-options-card/pricing-options-card.component';

@Component({
  selector: 'app-edit-dukan-product',
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
    NgFor,
    NgIf,
    NgClass,
    LoaderComponent,
    QuillModule,
    PricingOptionsCardComponent,
  ],
  templateUrl: './edit-dukan-product.component.html',
  styleUrls: ['./edit-dukan-product.component.scss'],
})
export class EditDukanProductComponent implements OnInit {
  public editProductFormGroup: FormGroup;

  public currency: string;

  public mediaSize = '3MB';

  public mediaGallery: { url: string; isVideo: boolean }[] = [];

  public assetsPath = 'assets/img/dukan/';

  public productSku: string;

  public isEnglishLanguage = false;

  public languageChangeSubscription: Subscription;

  public isLoading = false;

  public mediaIsUploading = false;

  public isTextEditorEnabled = false;

  public isEditModeEnabled = false;

  public shouldDisableDeleteButton = false;

  public quillConfiguration = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      ['emoji'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ],
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': true,
  };

  private _multitenancyService = inject(MultitenancyService);

  private _route = inject(ActivatedRoute);

  private _getDukanProductDetailsUseCase = inject(GetDukanProductDetailsUseCase);

  private _siteTranslateService = inject(SiteTranslateService);

  private _translateService = inject(TranslateService);

  private _updateDukanProductDetailsUseCase = inject(UpdateDukanProductDetailsUseCase);

  private _deleteDukanProductMediaUseCase = inject(DeleteDukanProductMediaUseCase);

  private _addDukanProductMediaUseCase = inject(AddDukanProductMediaUseCase);

  private _toast = inject(ToastrService);

  private _router = inject(Router);

  private _appURLs = inject(appUrlsConstantsInjectionToken);

  private _getFeatureFlagUsecase = inject(GetFeatureFlagUsecase);

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  ngOnInit(): void {
    this.setupTextEditor();
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this.languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
      },
    });
    this.editProductFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      detailedDescription: new FormControl('', [Validators.required]),
      pricingOptions: new FormArray([
        new FormGroup({
          name: new FormControl('', [Validators.required]),
          description: new FormControl(''),
          quantity: new FormControl(0, [Validators.required, Validators.min(1)]),
          totalPrice: new FormControl(0, [Validators.required, Validators.min(1)]),
          isDefault: new FormControl(true),
          isEditMode: new FormControl(false),
        }),
      ]),
    });
    this.editProductFormGroup.get('pricingOptions')!.valueChanges.subscribe((priceOptions) => {
      this.isEditModeEnabled = priceOptions.some((priceOption: any) => priceOption.isEditMode);
      this.shouldDisableDeleteButton = this.pricingOptions.length === 1 || this.isEditModeEnabled;
    });
    this.currency = this._multitenancyService.getCurrentCountry().currency.arabicName;
    this.getProductSku();
    this.checkIsTextEditorEnabled();
  }

  private setupTextEditor(): void {
    const AlignClass = Quill.import('attributors/style/align');
    Quill.register(AlignClass, true);
  }

  toggleSelection(selectedIndex: number): void {
    this.pricingOptions.controls.forEach((priceOptionFormGroup, cardIndex) => {
      if (cardIndex !== selectedIndex) {
        priceOptionFormGroup.get('isDefault')!.setValue(false);
      }
    });
    if (!this.pricingOptions.at(selectedIndex).get('isDefault')!.value) {
      this.pricingOptions.at(0).get('isDefault')!.setValue(true);
    }
  }

  get pricingOptions(): FormArray {
    return this.editProductFormGroup.get('pricingOptions') as FormArray;
  }

  onEditorCreated(quill: any): void {
    // TODO: apply this except if the description already has certain alignment applied
    quill.format('align', this.isEnglishLanguage ? 'left' : 'right');
  }

  addPricingOption(): void {
    this.pricingOptions.push(
      new FormGroup({
        name: new FormControl('', [Validators.required]),
        description: new FormControl(''),
        quantity: new FormControl(0, [Validators.required, Validators.min(1)]),
        totalPrice: new FormControl(0, [Validators.required, Validators.min(1)]),
        isDefault: new FormControl(false),
        isEditMode: new FormControl(true),
      }),
    );
    this.shouldDisableDeleteButton = this.pricingOptions.length === 1 || this.isEditModeEnabled;
  }

  deletePricingOption(index: number): void {
    if (this.pricingOptions.at(index).get('isDefault')!.value) {
      this.pricingOptions.at(0).get('isDefault')!.setValue(true);
    }
    this.pricingOptions.removeAt(index);
    this.shouldDisableDeleteButton = this.pricingOptions.length === 1 || this.isEditModeEnabled;
  }

  checkIsTextEditorEnabled(): void {
    this._getFeatureFlagUsecase.execute(IS_RICH_TEXT_EDITOR_ENABLED).subscribe((flag) => {
      this.isTextEditorEnabled = flag;
    });
  }

  uploadFiles(event: any): void {
    this.mediaIsUploading = true;
    const file = event.target.files[0];
    const addedData = {
      sku: this.productSku,
      media: file,
    };
    // Uploaded file should be less than 3MB
    if (file.size < 3145728) {
      this._addDukanProductMediaUseCase
        .execute(addedData)
        .pipe(
          finalize(() => {
            this.mediaIsUploading = false;
          }),
        )
        .subscribe({
          next: (res) => {
            const isVideo = isVideoFromUrl(res.imageUrl);
            this.mediaGallery.push({ url: res.imageUrl, isVideo });
          },
          error: () => {
            this._toast.error(this._translateService.instant('STORES.DUKAN.ERRORS.UPDATED'));
          },
        });
    } else {
      this.mediaIsUploading = false;
      this._toast.error(this._translateService.instant('STORES.DUKAN.ERRORS.MAX_SIZE'));
    }
  }

  deleteMedia(url: string, deletedIndex: number): void {
    const deletedData = {
      sku: this.productSku,
      url,
    };
    this._deleteDukanProductMediaUseCase.execute(deletedData).subscribe({
      next: () => {
        this.mediaGallery.splice(deletedIndex, 1);
      },
      error: () => {
        this._toast.error(this._translateService.instant('STORES.DUKAN.ERRORS.UPDATED'));
      },
    });
  }

  getProductSku(): void {
    this._route.params.subscribe((params) => {
      this.productSku = params.id;
      this.getProductDetails();
    });
  }

  getProductDetails(): void {
    this.isLoading = true;
    this._getDukanProductDetailsUseCase
      .execute(this.productSku)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe((product) => {
        this.editProductFormGroup
          .get('name')!
          .patchValue(this.isEnglishLanguage ? product.name.en : product.name.ar);
        this.editProductFormGroup
          .get('detailedDescription')!
          .patchValue(this.isEnglishLanguage ? product.description.en : product.description.ar);
        this.shouldDisableDeleteButton =
          product.pricingOptions.length === 1 || this.isEditModeEnabled;
        const pricingOptionsLength = product.pricingOptions.length;
        for (let index = 1; index < pricingOptionsLength; index++) {
          this.addPricingOption();
        }
        this.editProductFormGroup.get('pricingOptions')!.patchValue(product.pricingOptions);
        this.mediaGallery = product.media
          .filter((media) => media !== '')
          .map((media) => {
            return { url: media, isVideo: isVideoFromUrl(media) };
          });
      });
  }

  onUpdateProductDetails(): void {
    const product: UpdateDukanProductModel = {
      title: this.editProductFormGroup.get('name')!.value,
      description: this.editProductFormGroup.get('detailedDescription')!.value,
      pricingOptions: this.editProductFormGroup.get('pricingOptions')!.value,
    };
    const data = { product, sku: this.productSku };
    this._updateDukanProductDetailsUseCase.execute(data).subscribe({
      next: () => {
        // Not p tag || not br nor /p tags || not including other tags
        const isFormatterUsedRegex = /<[^p]>|<[^/br]{2}>|<[^<>]{3,}>/;
        if (isFormatterUsedRegex.test(product.description)) {
          this._logMixpanelEventUseCase.execute({
            eventName: 'dukan_edit_product_text_formatter_used',
          });
        }
        this._toast.success(
          this._translateService.instant('STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.SUCCESS'),
        );
        this._router.navigate([this._appURLs.STORES_URL, this._appURLs.EDIT_DUKAN_URL]);
      },
      error: () => {
        this._toast.error(this._translateService.instant('STORES.DUKAN.ERRORS.UPDATED'));
      },
    });
  }

  onProductSpecificationsChange(): void {
    let specifications = this.editProductFormGroup.get('detailedDescription')!.value;
    specifications = specifications
      .replace(/\r/g, '')
      .split(/\n/)
      .map((line: any) => {
        if (line.charAt(0) !== '•') {
          line = `•${line}`;
        }
        if (line.charAt(1) !== ' ') {
          line = `• ${line.substring(1)}`;
        }
        return line;
      })
      .filter((line: any, idx: number, arr: any) => {
        if (idx !== arr.length - 1 && line.length === 2) {
          return false;
        }
        return true;
      })
      .join('\r\n');
    if (specifications.length === 2) {
      specifications = '';
    }
    this.editProductFormGroup.get('detailedDescription')!.setValue(specifications);
  }
}
