import { Clipboard } from '@angular/cdk/clipboard';
import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dukan-successfully-created',
  templateUrl: './dukan-successfully-created.component.html',
  styleUrls: ['./dukan-successfully-created.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class DukanSuccessfullyCreatedComponent {
  public assetsPath = 'assets/img/';

  constructor(
    private _dialog: MatDialog,
    private _clipboard: Clipboard,
    private _toastr: ToastrService,
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: { url: string; reactivated: boolean },
  ) {}

  closeDialog() {
    this._dialog.closeAll();
  }

  copyDukanSlug() {
    this._clipboard.copy(this.data.url);
    this._toastr.info(this._translateService.instant('STORES.DUKAN.CREATED.COPIED'));
  }
}
