import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';

@Component({
  selector: 'app-store-linked-successfully',
  templateUrl: './store-linked-successfully.component.html',
  styleUrls: ['./store-linked-successfully.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class StoreLinkedSuccessfullyComponent {
  public assetsURL = 'assets/img/';

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {}

  closeDialog(): void {
    this._router.navigate([this._appURLs.STORES_URL]);
    this._dialog.closeAll();
  }
}
