import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CreateDukanShopUseCase } from 'src/app/core/usecases/dukan-shop/create-dukan.usecase';
import { SlugifyPipe } from 'src/app/presentation/shared/pipes/slugify.pipe';

@Component({
  selector: 'app-setup-your-dukan',
  templateUrl: './setup-your-dukan.component.html',
  styleUrls: ['./setup-your-dukan.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatCheckboxModule, TranslateModule, SlugifyPipe],
})
export class SetupYourDukanComponent {
  public assetsPath = 'assets/img/';

  public storeName: string;

  public createDukanForm = new FormGroup({
    dukanName: new FormControl('', [Validators.required]),
    shippingFeesCheck: new FormControl(false, [Validators.requiredTrue]),
  });

  public dukanCreateRequestSubmitted = false;

  constructor(
    private _createShopUseCase: CreateDukanShopUseCase,
    public dialogRef: MatDialogRef<SetupYourDukanComponent>,
    private _toast: ToastrService,
    private _translateService: TranslateService,
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    const dukanData = {
      name: this.createDukanForm.value.dukanName!,
      slug: this.createDukanForm.value.dukanName!,
    };
    this._createShopUseCase.execute(dukanData).subscribe({
      next: (dukan) => {
        this.dukanCreateRequestSubmitted = true;
        this.dialogRef.close(dukan);
      },
      error: () => {
        this._toast.error(this._translateService.instant('STORES.DUKAN.ERRORS.CREATE'));
      },
    });
  }

  onInput(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const arabicRegex = /[\u0600-\u06FF]/;
    if (arabicRegex.test(input.value)) {
      input.value = input.value.replace(arabicRegex, '');
      event.preventDefault();
    }
  }
}
