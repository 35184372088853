<div class="options">
  <p class="body1--medium content-secondary-color">{{ priceCardIndex + 1 }}</p>
  <div class="options__card">
    <div
      class="options__card__upper same-row"
      *ngIf="!pricingOptionsFormGroup.get('isEditMode').value"
    >
      <div class="options__card__upper__toggle">
        <p class="caption1--medium content-secondary-color">
          {{ 'STORES.DUKAN.EDIT_PRODUCT.OPTIONS.DEFAULT' | translate }}
        </p>
        <mat-slide-toggle
          [formControl]="pricingOptionsFormGroup.get('isDefault')"
          [checked]="pricingOptionsFormGroup.get('isDefault').value"
          (change)="onToggleClicked()"
        ></mat-slide-toggle>
      </div>
      <div class="options__card__upper__buttons">
        <button
          class="options-btn options__card__upper__edit-btn caption1--medium"
          (click)="onEditClicked()"
          [disabled]="isEditModeEnabled"
        >
          {{ 'STORES.DUKAN.EDIT_PRODUCT.OPTIONS.EDIT' | translate }}
        </button>
        <button
          class="options-btn options__card__upper__delete-btn caption1--medium"
          (click)="onDeleteClicked()"
          [disabled]="shouldDisableDeleteButton"
        >
          {{ 'STORES.DUKAN.EDIT_PRODUCT.OPTIONS.DELETE' | translate }}
        </button>
      </div>
    </div>
    <div
      class="options__edit"
      *ngIf="pricingOptionsFormGroup.get('isEditMode').value"
      [formGroup]="pricingOptionsFormGroup"
    >
      <p
        class="body2--regular content-main-color"
        [ngClass]="{
          required: isNameInvalid && pricingOptionsFormGroup.controls.name.touched
        }"
      >
        {{ 'STORES.DUKAN.EDIT_PRODUCT.OPTIONS.NAME' | translate }}
        <span class="required">*</span>
      </p>
      <quill-editor
        class="caption1--medium name-editor notranslate"
        [modules]="quillNameConfiguration"
        [placeholder]="''"
        formControlName="name"
        (onContentChanged)="onNameChanged($event)"
      >
      </quill-editor>
      <p
        class="caption1--medium content-secondary-color"
        [ngClass]="{
          required: isNameInvalid && pricingOptionsFormGroup.controls.name.touched
        }"
      >
        {{ nameTextLength }}/{{ maxNameLength }}
      </p>
      <div class="options__edit__second-row">
        <div class="options__edit__container">
          <p
            class="body2--regular content-main-color"
            [ngClass]="{
              required:
                pricingOptionsFormGroup.controls.totalPrice.invalid &&
                pricingOptionsFormGroup.controls.totalPrice.touched
            }"
          >
            {{ 'STORES.DUKAN.EDIT_PRODUCT.OPTIONS.PRICE' | translate }}
            <span class="required">*</span>
          </p>
          <div class="input-container">
            <input
              class="options__edit__input content-main-color"
              type="number"
              formControlName="totalPrice"
            />
            <p class="options__edit__input__currency caption1--medium">
              {{ currency | currencyTranslate | translate | currencyShortName }}
            </p>
          </div>
        </div>
        <div class="options__edit__container">
          <p
            class="body2--regular content-main-color"
            [ngClass]="{
              required:
                pricingOptionsFormGroup.controls.quantity.invalid &&
                pricingOptionsFormGroup.controls.quantity.touched
            }"
          >
            {{ 'STORES.DUKAN.EDIT_PRODUCT.OPTIONS.QUANTITY_HEADER' | translate }}
            <span class="required">*</span>
          </p>
          <input
            class="options__edit__input content-main-color"
            type="number"
            formControlName="quantity"
          />
        </div>
      </div>
      <p
        class="body2--regular content-main-color"
        [ngClass]="{
          required: isDescriptionInvalid && pricingOptionsFormGroup.controls.description.touched
        }"
      >
        {{ 'STORES.DUKAN.EDIT_PRODUCT.OPTIONS.DESCRIPTION_HEADER' | translate }}
      </p>
      <quill-editor
        class="caption1--medium name-editor notranslate"
        [modules]="quillDescriptionConfiguration"
        [placeholder]="''"
        formControlName="description"
        (onContentChanged)="onDescriptionChanged($event)"
      >
      </quill-editor>
      <p
        class="caption1--medium content-secondary-color"
        [ngClass]="{
          required: isDescriptionInvalid && pricingOptionsFormGroup.controls.description.touched
        }"
      >
        {{ descriptionTextLength }}/{{ maxDescriptionLength }}
      </p>
      <div class="options__edit__buttons">
        <button
          class="options-btn options__card__upper__save-btn caption1--medium"
          (click)="onSaveClicked()"
          [disabled]="pricingOptionsFormGroup.invalid || isDescriptionInvalid || isNameInvalid"
        >
          {{ 'STORES.DUKAN.EDIT_PRODUCT.OPTIONS.SAVE' | translate }}
        </button>
        <button
          class="options-btn options__card__upper__edit-btn caption1--medium"
          (click)="onCancelClicked()"
        >
          {{ 'STORES.DUKAN.EDIT_PRODUCT.OPTIONS.CANCEL' | translate }}
        </button>
      </div>
    </div>
    <div class="options__card__lower" *ngIf="!pricingOptionsFormGroup.get('isEditMode').value">
      <div class="options__card__lower__piece">
        <p class="body2--bold content-main-color">
          <span [innerHTML]="pricingOptionsFormGroup.value.name"></span>
        </p>
        <div class="same-row">
          <p class="caption1--medium content-secondary-color">
            {{ 'STORES.DUKAN.EDIT_PRODUCT.OPTIONS.DESCRIPTION' | translate }}
          </p>
          <p
            class="caption1--medium content-secondary-color description-text"
            [innerHTML]="pricingOptionsFormGroup.value.description"
          ></p>
        </div>
      </div>
      <div class="options__card__lower__price">
        <p class="body2--bold content-brand-color">
          {{ pricingOptionsFormGroup.value.totalPrice }}
          <span class="body2--bold content-brand-color right-space">{{
            currency | currencyTranslate | translate | currencyShortName
          }}</span>
        </p>
        <p class="caption1--medium content-secondary-color">
          {{ 'STORES.DUKAN.EDIT_PRODUCT.OPTIONS.QUANTITY' | translate }}
          {{ pricingOptionsFormGroup.value.quantity }}X
        </p>
      </div>
    </div>
  </div>
</div>
