import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-youcan-checkout-pop-up',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './youcan-checkout-pop-up.component.html',
  styleUrls: ['./youcan-checkout-pop-up.component.scss'],
})
export class YoucanCheckoutPopUpComponent {
  public assetsPath = 'assets/img/stores/';

  private _dialogRef = inject(MatDialogRef<YoucanCheckoutPopUpComponent>);

  closeClicked() {
    this._dialogRef.close(false);
  }

  continueClicked() {
    this._dialogRef.close(true);
  }
}
