<div class="deactivate-store">
  <section class="deactivate-store-text">
    <p class="deactivate-store-text--title body1--medium">
      {{ 'STORES.DUKAN.DEACTIVATE_DUKAN.TITLE' | translate }}
    </p>
    <p class="deactivate-store-text--body caption1--regular">
      {{ 'STORES.DUKAN.DEACTIVATE_DUKAN.BODY' | translate }}
    </p>
  </section>
  <section class="deactivate-store-CTAs">
    <button
      class="deactivate-store-CTAs--confirm caption1--medium"
      (click)="onConfirmDeactivateClicked()"
    >
      {{ 'STORES.DUKAN.DEACTIVATE_DUKAN.CONFIRM' | translate }}
    </button>
    <button class="deactivate-store-CTAs--cancel caption1--medium" (click)="onCancelClicked()">
      {{ 'STORES.DUKAN.DEACTIVATE_DUKAN.CANCEL' | translate }}
    </button>
  </section>
</div>
