import { Component, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-deactivate-store',
  templateUrl: './deactivate-store.component.html',
  styleUrls: ['./deactivate-store.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class DeactivateStoreComponent {
  onDeactivateClicked = new EventEmitter();

  constructor(private _dialog: MatDialog) {}

  onCancelClicked() {
    this._dialog.closeAll();
  }

  onConfirmDeactivateClicked() {
    this.onDeactivateClicked.emit();
  }
}
