import { NgClass, NgIf } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AdsIntegration } from 'src/app/core/domain/dukan-shop/dukan-shop.model';
import { GetDukanShopUseCase } from 'src/app/core/usecases/dukan-shop/get-dukan.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import {
  DUKAN_INTEGRATIONS_LABEL,
  DUKAN_PRODUCTS_LABEL,
  DUKAN_SETTINGS_LABEL,
} from 'src/app/presentation/shared/constants';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { DukanIntegrationsComponent } from '../dukan-integrations/dukan-integrations.component';
import { EditDukanProductComponent } from '../edit-dukan-product/edit-dukan-product.component';
import { DukanContactInfoCardComponent } from '../edit-your-dukan/dukan-contact-info-card/dukan-contact-info-card.component';
import { EditYourDukanComponent } from '../edit-your-dukan/edit-your-dukan.component';

@Component({
  selector: 'app-dukan-page',
  standalone: true,
  imports: [
    MatTabsModule,
    MatIconModule,
    EditYourDukanComponent,
    DukanContactInfoCardComponent,
    DukanIntegrationsComponent,
    EditDukanProductComponent,
    TranslateModule,
    RouterLink,
    NgClass,
    NgIf,
  ],
  templateUrl: './dukan-page.component.html',
  styleUrls: ['./dukan-page.component.scss'],
})
export class DukanPageComponent implements OnInit, OnDestroy {
  public isEnglishLanguage = false;

  public languageChangeSubscription: Subscription;

  public assetsPath = 'assets/img/dukan/';

  public dukanShopName: string;

  public selectedTabLabel = 'products';

  public editDukanPageSelected = false;

  @ViewChild('dukanProducts') dukanProducts: EditYourDukanComponent;

  @ViewChild('dukanSettings') dukanSettings: DukanContactInfoCardComponent;

  @ViewChild('dukanIntegrations') dukanIntegrations: DukanIntegrationsComponent;

  @ViewChild('dukanProductDetails') dukanProductDetails: EditDukanProductComponent;

  private _siteTranslateService = inject(SiteTranslateService);

  private _translateService = inject(TranslateService);

  private _getShopUseCase = inject(GetDukanShopUseCase);

  private _route = inject(ActivatedRoute);

  public appURLs = inject(appUrlsConstantsInjectionToken);

  @Input() integrationIds: AdsIntegration;

  @Input() dukanUrl: string;

  ngOnInit(): void {
    this.getDukanShop();
    this._route.url.subscribe((url) => {
      const currentRouter = url[0].path;
      this.editDukanPageSelected = currentRouter === this.appURLs.EDIT_DUKAN_URL;
    });
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this.languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
      },
    });
  }

  getDukanShop() {
    this._getShopUseCase.execute().subscribe({
      next: (res) => {
        this.dukanShopName = res.name;
        this.integrationIds = res.integrations;
        this.dukanUrl = res.url;
      },
      error: () => {},
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedTabLabel = tabChangeEvent.tab.textLabel;
  }

  onSaveChanges() {
    switch (this.selectedTabLabel) {
      case DUKAN_PRODUCTS_LABEL:
        if (this.editDukanPageSelected) {
          this.dukanProducts.onSetProducts();
        } else {
          this.dukanProductDetails.onUpdateProductDetails();
        }
        break;
      case DUKAN_SETTINGS_LABEL:
        this.dukanSettings.onUpdate();
        break;
      case DUKAN_INTEGRATIONS_LABEL:
        this.dukanIntegrations.updatePixelIntegration();
        break;
    }
  }

  ngOnDestroy(): void {
    this.languageChangeSubscription.unsubscribe();
  }
}
