import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DukanShopModel } from 'src/app/core/domain/dukan-shop/dukan-shop.model';
import { DeactivateDukanShopUseCase } from 'src/app/core/usecases/dukan-shop/deactivate-dukan.usecase';
import { GetDukanShopUseCase } from 'src/app/core/usecases/dukan-shop/get-dukan.usecase';
import { UpdateDukanInfoUseCase } from 'src/app/core/usecases/dukan-shop/update-dukan-info.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { EMAIL_REGULAR_EXPRESSION } from 'src/app/presentation/shared/constants';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { regexValidator } from 'src/app/presentation/shared/utilities/regexValidator.utility';
import { MetaPhoneNumberFieldComponent } from '../../../../shared/components/meta-phone-number-field/meta-phone-number-field.component';
import { DeactivateStoreComponent } from '../../deactivate-store/deactivate-store.component';
import { StoreSuccessfullyDeactivatedComponent } from '../../store-successfully-deactivated/store-successfully-deactivated.component';

interface InfoForm {
  email: FormControl<string | null>;
  phoneNumber?: FormControl<string | null>;
  checkoutType: FormControl<boolean | null>;
}
@Component({
  selector: 'app-dukan-contact-info-card',
  templateUrl: './dukan-contact-info-card.component.html',
  styleUrls: ['./dukan-contact-info-card.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MetaPhoneNumberFieldComponent,
    TranslateModule,
    MatRadioModule,
    MatIconModule,
  ],
})
export class DukanContactInfoCardComponent implements OnInit {
  public assetsPath = 'assets/img/dukan/';

  infoForm = new FormGroup<InfoForm>({
    email: new FormControl('', [
      Validators.required,
      regexValidator(new RegExp(EMAIL_REGULAR_EXPRESSION), { validEmail: true }),
    ]),
    phoneNumber: new FormControl(''),
    checkoutType: new FormControl(false),
  });

  user = {
    email: undefined,
    phonePrefix: undefined,
    phoneNumber: undefined,
  };

  enterNumberClicked = false;

  constructor(
    private _getShopUseCase: GetDukanShopUseCase,
    private _updateDukanInfoUseCase: UpdateDukanInfoUseCase,
    private _toast: ToastrService,
    private _translateService: TranslateService,
    private _router: Router,
    private _deactivateShopUseCase: DeactivateDukanShopUseCase,
    private _siteTranslateService: SiteTranslateService,
    private _dialog: MatDialog,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {}

  ngOnInit(): void {
    this.getDukanShop();
  }

  getDukanShop(): void {
    this._getShopUseCase.execute().subscribe({
      next: (res) => {
        const dukanData = res;
        this.infoForm.get('checkoutType')!.patchValue(dukanData.hasEmbeddedCheckoutEnabled);
        if (dukanData.contactInfo.email) {
          this.infoForm.get('email')!.patchValue(dukanData.contactInfo.email);
        }
        if (dukanData.contactInfo.phoneNumber) {
          this.enterNumberClicked = true;
          this.infoForm.get('phoneNumber')!.patchValue(dukanData.contactInfo.phoneNumber);
        }
      },
    });
  }

  addNumberClicked(): void {
    this.enterNumberClicked = true;
  }

  onUpdate(): void {
    if (this.infoForm.dirty) {
      const updateInfo = {
        contactInfo: {
          email: this.infoForm.get('email')!.value,
          phoneNumber: this.infoForm.get('phoneNumber')!.value,
        },
        hasEmbeddedCheckoutEnabled: this.infoForm.get('checkoutType')!.value,
      };
      this._updateDukanInfoUseCase.execute(updateInfo as DukanShopModel).subscribe({
        next: () => {
          this._toast.success(
            this._translateService.instant('STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.SUCCESS'),
          );
          this._router.navigate([this._appURLs.STORES_URL]);
        },
        error: () => {
          this._toast.error(this._translateService.instant('STORES.DUKAN.ERRORS.UPDATED'));
        },
      });
    }
  }

  onDeactivateClicked(): void {
    const dialogRef = this._dialog.open(DeactivateStoreComponent, {
      width: '450px',
      panelClass: 'linked-store-dialog',
      direction: this._siteTranslateService.currentDir,
    });
    dialogRef.componentInstance.onDeactivateClicked.subscribe({
      next: () => {
        this._deactivateShopUseCase.execute().subscribe({
          next: () => {
            const dialogRef2 = this._dialog.open(StoreSuccessfullyDeactivatedComponent, {
              width: '450px',
              panelClass: 'linked-store-dialog',
            });
            dialogRef2.afterClosed().subscribe({
              next: () => {
                this._router.navigate([this._appURLs.STORES_URL]);
              },
            });
          },
          error: () => {
            this._toast.error(this._translateService.instant('STORES.DUKAN.ERRORS.DEACTIVATE'));
          },
        });
      },
    });
  }
}
