<div class="youcan-checkout">
  <img
    loading="lazy"
    class="youcan-checkout__close"
    (click)="closeClicked()"
    alt="close-icon"
    src="{{ assetsPath + 'close.svg' }}"
  />
  <img src="{{ assetsPath + 'checkout.svg' }}" alt="youcan-checkout-img" />
  <div class="youcan-checkout__content">
    <p class="body1--bold content-main-color">
      {{ 'STORES.YOUCAN.CHECKOUT_HEADER' | translate }}
    </p>
    <p class="body2--medium content-medium-color">
      {{ 'STORES.YOUCAN.CHECKOUT_DESCRIPTION' | translate }}
    </p>
    <button (click)="continueClicked()" class="youcan-checkout__content__button body2--medium">
      {{ 'STORES.YOUCAN.CHECKOUT_CONTINUE' | translate }}
    </button>
  </div>
</div>
