import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-store-successfully-deactivated',
  templateUrl: './store-successfully-deactivated.component.html',
  styleUrls: ['./store-successfully-deactivated.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class StoreSuccessfullyDeactivatedComponent {
  public assetsPath = 'assets/img/dukan/';

  constructor(private _dialog: MatDialog) {}

  onCloseClicked() {
    this._dialog.closeAll();
  }
}
