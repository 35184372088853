<div class="container" [ngClass]="{ container__selected: product.status === 'active' }">
  <div *ngIf="product.status === 'active'; then thenBlock; else elseBlock"></div>
  <ng-template #thenBlock>
    <p class="title caption1--medium">
      {{ 'STORES.DUKAN.DUKAN_PROD_CARD.TOGGLE.SELECTED' | translate }}
    </p>
  </ng-template>
  <ng-template #elseBlock>
    <p class="title caption1--medium">
      {{ 'STORES.DUKAN.DUKAN_PROD_CARD.TOGGLE.UNSELECTED' | translate }}
    </p>
  </ng-template>
  <mat-slide-toggle
    class="signup-details-store-toggle"
    (change)="setSliderValue($event)"
    [checked]="product.status === 'active'"
  ></mat-slide-toggle>
</div>
